import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import RoutingService from "./Services/RoutingService";
import CookieService from "./Services/CookieService";
import es from './es'
import pt from './pt'

i18n
    .use(initReactI18next)
    .init({
        lng: RoutingService.getParamValue('locale', CookieService.getLocale()),
        fallbackLng: "en",
        keySeparator: false,
        interpolation: { escapeValue: false },

        resources: {
            es: es,
            pt: pt,
        },
    });
